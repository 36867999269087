import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../utilities/axios';
import * as API_END_POINT from '../utilities/constant';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    const [storeDetails, setStoreDetails] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(() => {
        const savedCustomerInfo = localStorage.getItem('customerInfo');
        return savedCustomerInfo ? JSON.parse(savedCustomerInfo) : {
            contactEmail: '',
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            country: { value: 1, label: "United States" },
            state: '',
            city: '',
            zip: '',
            phoneNumber: ''
        };
    });
    const [brandsCartCalc, setBrandsCartCalc] = useState([]);
    const [storeOrderStatus, setStoreOrderStatus] = useState([]);

    const getStoreDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.get(API_END_POINT.STORE_DETAILS);
            if (response && response.data && response.data.code === 200) {
                setStoreDetails(response.data.data);
                await getCart();
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const addToCart = async (data) => {
        try {
            setLoading(true);
            data.device_id = localStorage.getItem('device_id');
            const response = await axios.post(API_END_POINT.STORE_ADD_TO_CART, data);

            if (response && response.data && response.data.code === 201) {
                await getCart();
            }
        } catch (err) {
            console.log('Failed to add item to cart');
            console.log(err)
        } finally {
            setLoading(false);
        }
    };

    const getCart = async (zipcode = '') => {
        try {
            setLoading(true);
            let deviceId = localStorage.getItem('device_id');
            const response = await axios.get(API_END_POINT.STORE_CART_DATA(deviceId, zipcode));

            if (response && response.data && response.data.code === 200) {
                setCartItems(response.data.data);
            }
        } catch (err) {
            console.log('Failed to fetch cart data');
        } finally {
            setLoading(false);
        }
    };

    const removeCartProduct = async (data) => {
        try {
            setLoading(true);
            const device_id = localStorage.getItem('device_id');
            const config = {
                data: { ...data, device_id },
            };
            await axios.delete(API_END_POINT.STORE_CART_REMOVE_PRODUCT, config);
            await getCart();
        } catch (err) {
            console.log('Failed to remove product from cart');
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getStoreDetails();
    }, []);

    useEffect(() => {
        getCart(customerInfo.zip);
    }, [customerInfo, storeOrderStatus])

    useEffect(() => {
        localStorage.setItem('customerInfo', JSON.stringify(customerInfo));
    }, [customerInfo]);

    return (
        <StoreContext.Provider value={{
            storeDetails,
            cartItems,
            loading,
            error,
            getStoreDetails,
            addToCart,
            getCart,
            removeCartProduct,
            customerInfo,
            setCustomerInfo,
            setBrandsCartCalc,
            brandsCartCalc,
            setStoreOrderStatus,
            storeOrderStatus
        }}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
};

export default { StoreProvider, useStore };