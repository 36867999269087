import React, { useState } from 'react';

const ProductTabs = ({ productData }) => {
  const [activeTab, setActiveTab] = useState('details');

  const renderProductDesc = (data) => {
    const newData = `"${data}"`;
    if (newData.search('&lt;') >= 0) {
      const dummyElem = document.createElement('DIV');
      dummyElem.innerHTML = data;
      document.body.appendChild(dummyElem);
      const decodedHtml = dummyElem.textContent;
      document.body.removeChild(dummyElem);
      return decodedHtml;
    }
    return data;
  };

  return (
    <div className="mt-8">
      <div className="border-b flex">
        <button
          className={`font-semibold py-2 px-4 ${activeTab === 'details' ? 'border-b-2 border-prm' : 'text-gray-500'}`}
          onClick={() => setActiveTab('details')}
        >
          Product Details
        </button>
        <button
          className={`font-semibold py-2 px-4 ${activeTab === 'brand' ? 'border-b-2 border-prm ' : 'text-gray-500'}`}
          onClick={() => setActiveTab('brand')}
        >
          About the Brand
        </button>
      </div>
      <div className="mt-4 space-y-4 text-sm">
        {activeTab === 'details' ? (
          <div
            dangerouslySetInnerHTML={{
              __html: renderProductDesc(
                productData?.productDetails?.body_html
              ),
            }}
          ></div>
        ) : (
          <p>{productData.productDetails.user.brand_details.brand_story}</p>
        )}
      </div>
    </div>
  );
};

export default ProductTabs;