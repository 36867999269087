import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PatternFormat } from 'react-number-format';
import OrderListing from '../OrderListing';
import axios from '../../utilities/axios';
import * as API_END_POINT from '../../utilities/constant';
import Select from 'react-select';
import { useStore } from '../../context/StoreContext';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
    contactEmail: yup
        .string()
        .email('Must be a valid email.')
        .min(5)
        .max(254)
        .required('Please fill this field'),
    firstName: yup.string().required('Please fill this field').matches(/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.').min(1).max(50),
    lastName: yup.string().required('Please fill this field').matches(/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.').min(1).max(50),
    phoneNumber: yup
        .string()
        .required('Please fill this field')
        .matches(
            /\d{3}-\d{3}-\d{4}/,
            'Phone number must be in the format ###-###-####'
        ),
    addressLine1: yup.string().required('Please fill this field').min(4).max(40),
    country: yup.object().nullable().required('Please fill this field'),
    state: yup.object().nullable().required('Please fill this field'),
    city: yup.string().required('Please fill this field').matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.').min(2).max(50),
    zip: yup
        .string()
        .nullable()
        .required('Please fill this field')
        .matches(/^\d{5}$/, 'Zip code should contain 5 digits'),
});

export default function CustomerInformation() {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const { customerInfo, setCustomerInfo } = useStore();
    const navigate = useNavigate();
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            'contactEmail': customerInfo?.contactEmail,
            'firstName': customerInfo?.firstName,
            'lastName': customerInfo?.lastName,
            'addressLine1': customerInfo?.addressLine1,
            'addressLine2': customerInfo?.addressLine2,
            'country': { value: 1, label: "United States" },
            'state': customerInfo?.state,
            'city': customerInfo?.city,
            'zip': customerInfo?.zip,
            'phoneNumber': customerInfo?.phoneNumber
        },
        resolver: yupResolver(schema),

    });


    useEffect(() => {
        fetchCountries();
        fetchStates(1);
    }, []);

    const fetchCountries = async () => {
        try {
            const response = await axios.get(API_END_POINT.STORE_COUNTRY);
            if (response && response.data && response.data.code === 200) {
                setCountries(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching countries:', err);
        }
    };

    const fetchStates = async (countryId) => {
        try {
            const response = await axios.get(API_END_POINT.STORE_STATES(countryId));
            if (response && response.data && response.data.code === 200) {
                setStates(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching states:', err);
        }
    };

    const onSubmit = (data) => {
        console.log(data);
        setCustomerInfo(data);
        navigate('/checkout/payment-details')
    };

    useEffect(() => {
        if (countries?.length > 0) {
            const c = [];
            countries?.map(v => {
                c.push({ value: v?.id, label: v?.name });
            })
            setCountry(c);
        }
    }, [countries])
    useEffect(() => {
        if (states?.length > 0) {
            const s = [];
            states?.map(v => {
                s.push({ value: v?.code, label: v?.name });
            })
            setState(s);
        }
    }, [states])

    const getSelectBoxStyle = (isError = false) => {
        return {
            control: (styles) => {
                return {
                    ...styles,
                    borderColor: isError ? '#eb5757 !important' : '#cccccc',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                        boxShadow: 'none !important',
                    },
                };
            },
            container: (style) => {
                return {
                    ...style,
                    marginTop: '5px',
                    marginRight: '1px',
                };
            },
        };
    };

    const getSelectBoxTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#fbf5f0',
            primary: '#bd6f34',
        },
    });


    return (
        <div className="relative payment-details-container">

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <h1 className="text-2xl font-bold mb-6 text-gray-800">Customer information</h1>
                <div className="payment-details-layout">
                    <div className="order-summary space-y-4 border border-gray-300 rounded-lg p-4">
                        <OrderListing isFromPayment={false} />
                    </div>

                    <div className="payment-form">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 border border-gray-300 rounded-lg p-4">
                            <div>
                                <label htmlFor="email" className="block text-sm text-gray-500 mb-1">
                                    Email <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="email"
                                    {...register('contactEmail')}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                    placeholder="johndoe@gmail.com"
                                />
                                {errors.contactEmail && <p className="mt-1 text-sm text-red-600">{errors.contactEmail.message}</p>}
                                <p className="mt-1 text-xs text-gray-500">We will send order confirmation to this email address</p>
                            </div>

                            <hr className="border-gray-200" />

                            <h2 className="text-xl font-semibold text-gray-800">Shipping address</h2>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label htmlFor="firstName" className="block text-sm text-gray-500 mb-1">
                                        First name <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register('firstName')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                        placeholder="Enter the first name of user"
                                    />
                                    {errors.firstName && <p className="mt-1 text-sm text-red-600">{errors.firstName.message}</p>}
                                </div>

                                <div>
                                    <label htmlFor="lastName" className="block text-sm text-gray-500 mb-1">
                                        Last name <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register('lastName')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                        placeholder="Enter the last name of user"
                                    />
                                    {errors.lastName && <p className="mt-1 text-sm text-red-600">{errors.lastName.message}</p>}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="addressLine1" className="block text-sm text-gray-500 mb-1">
                                    Address line 1 <span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register('addressLine1')}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                    placeholder="Suite/Building number, floor number, street number etc..."
                                />
                                {errors.addressLine1 && <p className="mt-1 text-sm text-red-600">{errors.addressLine1.message}</p>}
                            </div>

                            <div>
                                <label htmlFor="addressLine2" className="block text-sm text-gray-500 mb-1">
                                    Address line 2
                                </label>
                                <input
                                    type="text"
                                    {...register('addressLine2')}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                    placeholder="Other address details, if needed"
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label htmlFor="country" className="block text-sm text-gray-500 mb-1">
                                        Country <span className="text-red-500">*</span>
                                    </label>
                                    <Controller
                                        name={'country'}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="basic-single_top mb-0"
                                                placeholder="Select country"
                                                classNamePrefix="select"
                                                styles={getSelectBoxStyle()}
                                                components={{
                                                    IndicatorSeparator:
                                                        () => null,
                                                }}
                                                theme={(theme) =>
                                                    getSelectBoxTheme(
                                                        theme
                                                    )
                                                }
                                                options={country}
                                            />
                                        )}
                                    />
                                    {errors.country && <p className="mt-1 text-sm text-red-600">{errors.country.message}</p>}
                                </div>

                                <div>
                                    <label htmlFor="state" className="block text-sm text-gray-500 mb-1">
                                        State <span className="text-red-500">*</span>
                                    </label>
                                    <Controller
                                        name={'state'}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="basic-single_top  mb-0"
                                                placeholder="Select state"
                                                classNamePrefix="select"
                                                styles={getSelectBoxStyle()}
                                                components={{
                                                    IndicatorSeparator:
                                                        () => null,
                                                }}
                                                theme={(theme) =>
                                                    getSelectBoxTheme(
                                                        theme
                                                    )
                                                }
                                                options={state}
                                            />
                                        )}
                                    />
                                    {errors.state && <p className="mt-1 text-sm text-red-600">{errors.state.message}</p>}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label htmlFor="city" className="block text-sm text-gray-500 mb-1">
                                        City <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register('city')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                        placeholder="Enter your city name"
                                    />
                                    {errors.city && <p className="mt-1 text-sm text-red-600">{errors.city.message}</p>}
                                </div>

                                <div>
                                    <label htmlFor="zip" className="block text-sm text-gray-500 mb-1">
                                        ZIP <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register('zip')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                        placeholder="12345"
                                        maxLength={5}
                                    />
                                    {errors.zip && <p className="mt-1 text-sm text-red-600">{errors.zip.message}</p>}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="phoneNumber" className="block text-sm text-gray-500 mb-1">
                                    Phone number <span className="text-red-500">*</span>
                                </label>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <PatternFormat
                                            format="###-###-####"
                                            value={value}
                                            onChange={onChange}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                            placeholder="XXX-XXX-XXXX"
                                        />
                                    )}
                                />
                                {errors.phoneNumber && <p className="mt-1 text-sm text-red-600">{errors.phoneNumber.message}</p>}
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-4 rounded-full transition duration-300"
                            >
                                Continue to Payment
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <style jsx>{`
                @media (min-width: 1024px) {
                    .payment-details-layout {
                        display: flex;
                        flex-direction: row;
                        gap: 2rem;
                    }
                    .payment-form {
                        width: 60%;
                        order: -1;
                    }
                    .order-summary {
                        width: 40%;
                    }
                }
            `}</style>
        </div>
    );
}