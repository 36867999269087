import React from 'react';

const SkeletonLoader = ({ type }) => {
    switch (type) {
        case 'image':
            return (
                <div className="md:w-1/2">
                    <div className="aspect-square bg-gray-200 rounded-lg animate-pulse"></div>
                </div>
            );
        case 'productInfo':
            return (
                <div className="md:w-1/2 space-y-4">
                    <div className="h-8 bg-gray-200 rounded w-3/4 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2 animate-pulse"></div>
                    <div className="h-6 bg-gray-200 rounded w-1/4 animate-pulse"></div>
                    <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                    </div>
                    <div className="h-10 bg-gray-200 rounded w-full animate-pulse"></div>
                </div>
            );
        case 'tabs':
            return (
                <div className="mt-8 space-y-4">
                    <div className="h-10 bg-gray-200 rounded w-1/2 animate-pulse"></div>
                    <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                        <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse"></div>
                    </div>
                </div>
            );
        case 'productCard':
            return (
                <div className="bg-gray-200 animate-pulse rounded-lg border border-gray-300 overflow-hidden">
                    <div className='px-6 pt-6 h-60 bg-gray-300 rounded-md'></div>
                    <div className="p-4">
                        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
                        <div className="mt-2 flex justify-between items-center">
                            <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                            <div className="h-6 bg-gray-300 rounded w-16"></div>
                        </div>
                    </div>
                </div>
            )
        case 'banner':
            return (
                <div className="text-center mb-8 animate-pulse">
                    <div className="inline-block rounded-full p-1 overflow-hidden mb-8 border-2 border-secondary shadow-lg bg-gray-200">
                        <div className="w-32 h-32 rounded-full bg-gray-300" />
                    </div>
                    <div className="max-w-4xl mx-auto px-8 py-6 border border-gray-200 rounded-lg shadow-sm relative">
                        <div className="h-8 bg-gray-200 rounded w-64 mx-auto mb-4" />
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded" />
                            <div className="h-4 bg-gray-200 rounded" />
                            <div className="h-4 bg-gray-200 rounded" />
                            <div className="h-4 bg-gray-200 rounded w-5/6 mx-auto" />
                        </div>
                        <div className="flex justify-center mt-6 space-x-6">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="w-8 h-8 bg-gray-200 rounded-md" />
                            ))}
                        </div>
                    </div>
                </div>
            )
        default:
            return null;
    }
};

export default SkeletonLoader;