import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/file';

const ProductImages = ({ images, activeImage }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const newIndex = images.findIndex(img => img.src === activeImage?.src);
    if (newIndex !== -1) {
      setActiveIndex(newIndex);
    }
  }, [activeImage, images]);

  return (
    <div className="w-full max-w-2xl mx-auto">
      <div className="md:hidden">
        <div className="relative w-full aspect-square overflow-hidden rounded-lg mb-2 max-h-96">
          {images[activeIndex].src.endsWith('.mp4') ? (
            <ReactPlayer
              url={images[activeIndex].src}
              playing
              controls
              width="100%"
              height="100%"
              className="absolute top-0 left-0"
            />
          ) : (
            <img 
              src={images[activeIndex].src} 
              alt={`Product ${activeIndex + 1}`} 
              className="w-full h-full object-contain"
            />
          )}
        </div>
        <div className="flex justify-center space-x-2 overflow-x-auto">
          {images.map((img, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`flex-shrink-0 w-16 h-16 overflow-hidden rounded ${activeIndex === index ? 'border-2 border-prm' : ''}`}
            >
              <img 
                src={img.src} 
                alt={`Thumbnail ${index + 1}`} 
                className="w-full h-full object-cover" 
              />
            </button>
          ))}
        </div>
      </div>

      <div className="hidden md:flex md:flex-row">
        <div className="flex flex-col mr-4 space-y-2 overflow-y-auto max-h-96">
          {images.map((img, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`flex-shrink-0 w-16 h-16 overflow-hidden cursor-pointer rounded ${activeIndex === index ? 'border-2 border-prm' : ''}`}
            >
              <img src={img.src} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-cover" />
            </button>
          ))}
        </div>
        <div className="flex-grow relative aspect-square max-w-xl max-h-96">
          {images[activeIndex].src.endsWith('.mp4') ? (
            <ReactPlayer
              url={images[activeIndex].src}
              playing
              controls
              width="100%"
              height="100%"
              className="absolute top-0 left-0"
            />
          ) : (
            <img 
              src={images[activeIndex].src} 
              alt={`Product ${activeIndex + 1}`} 
              className="w-full h-full object-contain rounded-lg" 
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductImages;