import React, { useState, useEffect } from 'react';
import { X, Minus, Plus, Trash2 } from 'lucide-react';
import { useStore } from '../../context/StoreContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import RemoveIcon from '../../assets/images/icons/remove-icon.svg';
import { truncateText } from '../../utilities/helper';

const isCurrentTimeWithinDateRange = (startDate, endDate) => {
  const currentDate = new Date();
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date(8640000000000000);
  return currentDate >= start && currentDate <= end;
};

const CartViewSkeleton = () => (
  <div className="animate-pulse space-y-4">
    <div className="h-4 bg-gray-200 rounded w-1/4"></div>
    <div className="h-20 bg-gray-200 rounded"></div>
    <div className="h-20 bg-gray-200 rounded"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    <div className="h-10 bg-gray-200 rounded"></div>
  </div>
);

const CartView = ({ isOpen, onClose }) => {
  const { cartItems, storeDetails, addToCart, removeCartProduct, loading } = useStore();
  const [isVisible, setIsVisible] = useState(false);

  const handleRemoveProduct = (id) => {
    const body = { "id": id }
    removeCartProduct(body);
  }

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  const handleInputChange = (event, item, cart) => {
    let qty = Math.max(1, parseInt(event.target.value) || 1);
    let availableQty = item.product_variants.allowed_inventory_quantity;
    let inventoryTracked = item.product_variants.inventory_management;

    if (availableQty >= qty || !inventoryTracked) {
      updateCart(cart, item, qty);
    } else {
      toast.error('Stock limit reached', { autoClose: 3000 });
    }
  };

  const handleCheckOut = () => {
    onClose();
  };

  const updateCart = (cart, item, qty) => {
    const payload = {
      brands: [
        {
          brand_user_id: cart?.brand_user?.id,
          products: [
            {
              product_id: item?.products?.id,
              variants: [
                {
                  variant_id: item?.product_variants?.id,
                  quantity: qty,
                },
              ],
              customizableNote: item?.customizable_note,
            },
          ],
        },
      ],
    };
    addToCart(payload);
  };

  const getDiscountValue = (price, brand_discount) => {
    let discount = brand_discount?.discount_percentage ? parseFloat(brand_discount?.discount_percentage) : 0;
    let start_date = brand_discount?.start_date;
    let end_date = brand_discount?.end_date;
    let discount_percentage = 0;

    if (discount && isCurrentTimeWithinDateRange(start_date, end_date) &&
      storeDetails?.user?.subscription?.recurly_plan_code !== 'retailer-starter-monthly-plan') {
      discount_percentage = discount;
    }

    const prices = price?.split('-').map(v => parseFloat(v));
    const discountedPrices = prices.map(v =>
      discount_percentage ? (v - (v * discount_percentage / 100)).toFixed(2) : v.toFixed(2)
    );

    return discountedPrices.join("-");
  };

  const calcTotal = () => {
    return cartItems.reduce((total, cart) => {
      const brandTotal = cart.cart_items.reduce((brandSum, item) => {
        if (!isProductUnavailable(cart, item)) {
          const price = parseFloat(getDiscountValue(item.product_variants.msrp_price, item.products.user.brand_discount).split('-')[0]);
          return brandSum + (price * item.cart_quantity);
        }
        return brandSum;
      }, 0);
      return total + brandTotal;
    }, 0);
  };

  const isProductUnavailable = (productsByBrand, item) => {
    const pendingStock = item.product_variants.allowed_inventory_quantity;
    const inventoryTracked = item.product_variants.inventory_management;

    if (!inventoryTracked) return false;
    if (pendingStock === 0) return true;
    if (item.product_variants.status !== '1') return true;
    if (!item.products || item.products.status !== 1) return true;
    if (!productsByBrand?.brand_user?.access_token?.is_active) return true;
    if (item?.product_variants?.is_deleted) return true;
    if (item?.products?.store_products?.length === 0) return true;

    return false;
  };
  const calcBrandTotal = (cart) => {
    return cart.cart_items.reduce((brandSum, item) => {
      if (!isProductUnavailable(cart, item)) {
        const price = parseFloat(getDiscountValue(item.product_variants.msrp_price, item.products.user.brand_discount).split('-')[0]);
        return brandSum + (price * item.cart_quantity);
      }
      return brandSum;
    }, 0);
  };

  const cartContent = (
    <>
      <div className="sticky top-0 bg-white z-10 p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Your shopping bag</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={28} />
          </button>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto p-4">
        
          <>
            {cartItems.map((cart, i) => (
              <div key={i} className="mb-6 border-2 border-gray-200 rounded-lg p-4">
                <h3 className="font-semibold text-xl mb-3">{cart?.brand_user?.brand_details?.store_name}</h3>
                {cart.cart_items.map((item, index) => (
                  <div key={index} className="border-t first:border-t-0 pt-4 first:pt-0 mt-4 first:mt-0">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center justify-between">
                        <div className='relative'>
                          <img src={item?.product_variants?.image || item?.products?.product_images?.find(image => image?.is_main === '1')?.src} alt={item?.products?.title} className="w-20 h-20 object-cover rounded mr-4" />
                          <button onClick={() => handleRemoveProduct(item.id)} className="ml-4 text-red-500 hover:text-red-700">
                           <img src={RemoveIcon} className='absolute top-[-5px] right-2'/>
                          </button>
                        </div>
                        <div>
                          <p className="font-semibold text-lg">{truncateText(item?.products?.title,30)}</p>
                          {item?.product_variants?.title !== "Default Title" && (
                            <p className="text-base text-gray-600">({item?.product_variants?.title})</p>
                          )}
                          {item?.customizable_note && (
                            <p className="text-base text-gray-600">Customized Tag Name: <span className='font-semibold'>{item?.customizable_note}</span></p>
                          )}
                          <div className="flex items-center mt-2">
                            <button
                              onClick={() => updateCart(cart, item, Math.max(1, item.cart_quantity - 1))}
                              className="text-gray-500 hover:text-gray-700"
                              disabled={isProductUnavailable(cart, item) || item.cart_quantity <= 1}
                            >
                              <Minus size={24} />
                            </button>
                            <input
                              type="number"
                              min="1"
                              value={item.cart_quantity}
                              onChange={(e) => handleInputChange(e, item, cart)}
                              className="mx-2 w-16 text-center border rounded text-lg"
                              disabled={isProductUnavailable(cart, item)}
                            />
                            <button
                              onClick={() => updateCart(cart, item, item.cart_quantity + 1)}
                              className="text-gray-500 hover:text-gray-700"
                              disabled={isProductUnavailable(cart, item)}
                            >
                              <Plus size={24} />
                            </button>

                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="text-lg text-gray-600">
                          {parseFloat(item.product_variants.msrp_price) !== parseFloat(getDiscountValue(item.product_variants.msrp_price, item.products.user.brand_discount)) && (
                            <span className="line-through mr-2">
                              ${(parseFloat(item.product_variants.msrp_price) * item.cart_quantity).toFixed(2)}
                            </span>
                          )}
                          <span className="text-green-700">
                            ${(parseFloat(getDiscountValue(item.product_variants.msrp_price, item.products.user.brand_discount)) * item.cart_quantity).toFixed(2)}
                          </span>
                        </p>
                      </div>
                    </div>
                    {isProductUnavailable(cart, item) && (
                      <p className="text-red-500 mt-2 text-base">Product not available. Please remove from shopping cart.</p>
                    )}
                  </div>
                ))}
                <div className="mt-4 pt-4 border-t flex justify-between items-center">
                  <span className="font-semibold text-lg">Subtotal</span>
                  <span className="font-semibold text-lg text-green-700">${calcBrandTotal(cart).toFixed(2)}</span>
                </div>
              </div>
            ))}
          </>
 
      </div>

      <div className="sticky bottom-0 bg-white z-10 p-4 border-t">
        <div className="flex justify-between items-center mb-4">
          <span className="font-semibold text-xl">Total</span>
          <span className="font-semibold text-xl">${calcTotal().toFixed(2)}</span>
        </div>
        <Link to="/checkout" >
          <button
            className="w-full bg-orange-500 text-white py-4 rounded-full font-semibold text-xl hover:bg-orange-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            onClick={handleCheckOut}
            disabled={cartItems.length === 0 || cartItems.every(cart => cart.cart_items.every(item => isProductUnavailable(cart, item)))}
          >
            Checkout
          </button>
        </Link>
      </div>
    </>
  );

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 hidden md:flex justify-end z-50">
        <div className="bg-white w-2/5 h-full flex flex-col p-6">
          {cartContent}
        </div>
      </div>

      <div className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300" onClick={onClose}>
        <div
          className={`fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl flex flex-col h-[90vh] transition-transform duration-300 ease-out ${isOpen ? 'translate-y-0' : 'translate-y-full'}`}
          onClick={e => e.stopPropagation()}
        >
          {cartContent}
        </div>
      </div>
    </>
  );
};

export default CartView;