import React from 'react';
import { useStore } from '../../context/StoreContext';
import SkeletonLoader from '../SkeletonLoader';
import Website from '../../assets/images/icons/website-icon.svg';
import Facebook from '../../assets/images/icons/facebook-icon.svg';
import Instagram from '../../assets/images/icons/instagram-icon.svg';
import Tiktok from '../../assets/images/icons/linkedin-icon.svg';
import Youtube from '../../assets/images/icons/youtube-icon.svg';


const Banner = () => {
  const { storeDetails, loading, error } = useStore();
  
  const StoreLinks = () => {
    const sortedLinks = storeDetails?.store_social_links.sort((a, b) => a.sequence - b.sequence);
    return sortedLinks
  }

  const getSocialImage = (type) => {
    if(type == 'Facebook')
    {
      return Facebook
    }
    else if(type == 'Website')
    {
      return Website
    }
    else if(type == 'Youtube')
    {
      return Youtube
    }
    else if(type == 'Tiktok')
    {
      return Tiktok
    }
    else if(type == 'Instagram')
    {
      return Instagram
    }
  }

  if (loading) {
    return <SkeletonLoader type={'banner'} />;
  }

  if (error) {
    return <div className="text-center text-red-500">Error loading store details</div>;
  }

  return (
    <div className="text-center mb-8">
      <div className="inline-block rounded-full p-1 overflow-hidden mb-8 border-2 border-secondary shadow-lg">
        <img src={storeDetails?.store_logo} className="w-32 h-32 object-cover rounded-full" />
      </div>
      <div className=" mx-auto px-8 py-10 border border-gray-200 rounded-lg shadow-sm relative">

        <div class="flex items-center justify-center w-full">
          <h1 className="text-5xl font-semibold text-navy50 top-[-27px] inline-block bg-white px-5 absolute m-auto">{storeDetails?.store_name}</h1>
        </div>

        <p className="text-gray-600 leading-relaxed">
          {storeDetails?.hero_text}
        </p>
        <div className='flex items-center justify-center w-full'>
          <div className="flex justify-center width-max-content space-x-6 absolute bg-white px-5 -bottom-[15px]">

          {StoreLinks()?.map((link) => (
                        <>
                          {
                            link?.url?.trim() &&
                            <a
                            key={link.id}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link"
                            title={link.type}
                          >
                            <img src={getSocialImage(link.type)} className='w-8' />
                          </a>
                          }
                        </>
                      
                      ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;