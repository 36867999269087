import React, { useMemo } from 'react';
import { useStore } from '../../context/StoreContext';
import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
  const { storeDetails } = useStore();

  const isCurrentTimeWithinDateRange = (startDate, endDate) => {
    const startDateTime = new Date(startDate).setHours(0, 0, 0, 0);
    const endDateTime = new Date(endDate || 86400000000000).setHours(23, 59, 59, 999);
    const currentDateTime = new Date().getTime();
    return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
  };

  const formatPrice = (price) => {
    return price?.split('-').map((v) => parseFloat(v).toFixed(2)).join('-');
  };

  const calculateDiscountedPrice = (price, discountPercentage) => {
    return price?.split('-')
      .map((v) => (v - (v * discountPercentage) / 100).toFixed(2))
      .join('-');
  };

  const getDiscountPercentage = (price, brand_discount) => {
    const discount = brand_discount?.discount_percentage ? parseFloat(brand_discount.discount_percentage) : 0;
    const { start_date, end_date } = brand_discount || {};
    const isEligibleForDiscount = discount && isCurrentTimeWithinDateRange(start_date, end_date) &&
      storeDetails?.user?.subscription?.recurly_plan_code !== 'retailer-starter-monthly-plan';

    if (isEligibleForDiscount) {
      const originalPrice = formatPrice(price);
      const discountedPrice = calculateDiscountedPrice(price, discount);

      return (
        <span className="text-gray-500">
          <s>${originalPrice}</s>{' '}
          <span className='text-lg font-bold text-green-700'>${discountedPrice}</span>
        </span>
      );
    }

    return <span className="text-lg font-bold ">${formatPrice(price)}</span>;
  };

  const mainImageSrc = useMemo(() => product?.product_images?.find(i => i?.is_main === "1")?.src, [product]);

  return (
    <Link to={`/product/${product.id}`} className="block bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-md transition-shadow duration-300">
      <div className='px-6 pt-6'>
        <img src={mainImageSrc} alt={product?.title} className="w-full h-60 rounded-md" />
      </div>
      <div className="p-4">
        <h6 className="text-[10px] text-gray-500">{product?.user?.brand_details?.store_name}</h6>
        <h3 className="text-sm font-semibold">{product?.title}</h3>
        <div className="mt-2 flex justify-between items-center">
          <div>
            {getDiscountPercentage(product?.price_msrp, product?.user?.brand_discount)}
          </div>
          <button className="bg-primary rounded-full text-white cursor-pointer text-sm font-semibold outline-none py-1 px-6 no-underline">
            Buy
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;