import { useState, useEffect } from 'react';
import axios from '../utilities/axios';
import * as API_END_POINT from '../utilities/constant';

const useProductData = (productId) => {
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await axios.get(API_END_POINT.STORE_PRODUCT_DETAILS(productId));
        setProductData(res.data.data);
      } catch (err) {
        setError(err);
        console.error('Error fetching product details', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [productId]);

  return { productData, loading, error };
};

export default useProductData;