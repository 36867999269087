import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import failedIcon from '../../assets/images/failed-icon.svg';
import successIcon from '../../assets/images/success-icon.svg';
import { useStore } from '../../context/StoreContext';

const OrderConfirmation = () => {
  const [successOrders, setSuccessOrders] = useState([]);
  const [failedOrders, setFailedOrders] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const { storeOrderStatus } = useStore();


  useEffect(() => {
    const placedOrders = storeOrderStatus;
    const success = [];
    const failed = [];
    placedOrders.forEach(order => {
      if (order.status === 'failure') {
        failed.push(order.brandStore);
      } else {
        success.push(order.brandStore);
        setOrderId(order.order_id);
      }
    });

    setSuccessOrders(success);
    setFailedOrders(failed);
  }, [storeOrderStatus]);

  return (
    <div className="min-h-screen bg-white p-4 mt-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <Link
            to="/"
            className="text-black font-bold underline"
          >
            Back to Home
          </Link>
          {successOrders.length > 0 && (
            <Link
              to={`/view-order/${orderId}`}
              className="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700"
            >
              View placed order
            </Link>
          )}
        </div>

        <div className="space-y-4">
          {successOrders.map((brand, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg p-6 text-center">
              <img src={successIcon} alt="Success" className="h-16 w-16 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold mb-2">Order placed with {brand}, thanks!</h2>
              <p className="text-gray-600">
                An email confirmation has been sent to you. If you didn't receive the email confirmation, email{' '}
                <a href="mailto:support@shopdotapp.com" className="text-orange-500 hover:underline">
                  support@shopdotapp.com
                </a>
              </p>
            </div>
          ))}

          {failedOrders.map((brand, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg p-6 text-center">
              <img src={failedIcon} alt="Failed" className="h-16 w-16 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold mb-2">Oh no, order for {brand} couldn't be processed</h2>
              <p className="text-gray-600">
                There was an issue when trying to process the order with {brand}. These items are still in your shopping cart. Please try again.
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;