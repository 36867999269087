import React from 'react';
import shopDot from '../../assets/images/shopdot.svg'
const Footer = () => (
    <footer className="bg-gray-800 text-white py-4 text-center">
        <div className="mb-2">
            <a className="text-sm mr-4" href='https://shopdotapp.com/privacy-policy/' target='_blank' rel="noreferrer">Privacy policy</a>
            <a className="text-sm" href='https://shopdotapp.com/terms-of-use-agreement/' target='_blank' rel="noreferrer">Terms of services</a>
        </div>
        <div className="text-md flex justify-center mt-4">
            Powered by 
            <img src={shopDot} alt="ShopDot" className='ml-2 mt-1 cursor-pointer'></img>
        </div>
    </footer>
);

export default Footer;