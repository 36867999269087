// CustomizableNote.js
import React, { useState, useEffect } from 'react';

const CustomizableNote = ({ limit, placeholder, value, onChange }) => {
  const [note, setNote] = useState(value || '');
  const [error, setError] = useState(null);

  useEffect(() => {
    setNote(value || '');
  }, [value]);

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setError(null);
    if (limit && newNote.length > limit) {
      setError(`Maximum ${limit} characters allowed`);
    } else {
      setNote(newNote);
      onChange(newNote);
    }
  };

  return (
    <div className="mb-4">
      <label htmlFor="customizableNote" className="block text-sm font-medium text-gray-700 mb-1">
        Add your customization
      </label>
      <input
        type="text"
        id="customizableNote"
        value={note}
        onChange={handleNoteChange}
        placeholder={placeholder || "Enter custom text"}
        className="w-full p-2 border rounded focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
      />
      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

export default CustomizableNote;