import { useState, useEffect } from 'react';

const useProductOptions = (productData, storeDetails) => {
  const [selectedOptions, setSelectedOptions] = useState({
    option1: null,
    option2: null,
    option3: null
  });
  const [allOptions, setAllOptions] = useState({
    option1: [],
    option2: [],
    option3: []
  });
  const [availableOptions, setAvailableOptions] = useState({
    option1: [],
    option2: [],
    option3: []
  });
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [activeImage, setActiveImage] = useState(null);
  const [allOptionsSelected, setAllOptionsSelected] = useState(false);

  useEffect(() => {
    if (productData) {
      initializeOptions();
    }
  }, [productData]);

  useEffect(() => {
    updateAvailableOptions();
    updateSelectedVariant();
    checkAllOptionsSelected();
  }, [selectedOptions, productData]);

  const isCurrentTimeWithinDateRange = (startDate, endDate) => {
    const startDateTime = new Date(startDate).setHours(0, 0, 0, 0);
    const endDateTime = new Date(endDate || 86400000000000).setHours(23, 59, 59, 999);
    const currentDateTime = new Date().getTime();
    return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
  };

  const initializeOptions = () => {
    const options = {
      option1: [],
      option2: [],
      option3: []
    };

    productData.productDetails.product_variants.forEach(v => {
      if (v.option1) options.option1.push(v.option1);
      if (v.option2) options.option2.push(v.option2);
      if (v.option3) options.option3.push(v.option3);
    });

    // Remove duplicates
    options.option1 = [...new Set(options.option1)];
    options.option2 = [...new Set(options.option2)];
    options.option3 = [...new Set(options.option3)];

    setAllOptions(options);

    // Find first available options
    const initialOptions = {};
    const availableVariants = productData.productDetails.product_variants.filter(
      v => (v.allowed_inventory_quantity > 0 && v.status === "1") || !v.inventory_management
    );

    if (availableVariants.length > 0) {
      const firstAvailableVariant = availableVariants[0];
      ['option1', 'option2', 'option3'].forEach(optionKey => {
        if (firstAvailableVariant[optionKey]) {
          initialOptions[optionKey] = firstAvailableVariant[optionKey];
        }
      });
    }
    setSelectedOptions(initialOptions);
    updateAvailableOptions();
  };

  const checkAllOptionsSelected = () => {
    const allSelected = Object.keys(allOptions).every(key => selectedOptions[key]);
    setAllOptionsSelected(allSelected);
  };

  const updateAvailableOptions = () => {
    const newAvailableOptions = {
      option1: [],
      option2: [],
      option3: []
    };

    productData.productDetails.product_variants.forEach(v => {
      if (v.allowed_inventory_quantity > 0 && v.status === "1" || !v.inventory_management) {
        newAvailableOptions.option1.push(v.option1);

        if (!selectedOptions.option1 && !selectedOptions.option2 && !selectedOptions.option3) {
          newAvailableOptions.option2.push(v.option2);
          newAvailableOptions.option3.push(v.option3);
        }

        if (selectedOptions.option1 === v.option1) {
          newAvailableOptions.option2.push(v.option2);
        }

        if (selectedOptions.option1 === v.option1 && selectedOptions.option2 === v.option2) {
          newAvailableOptions.option3.push(v.option3);
        }
      }
    });

    // Remove duplicates
    newAvailableOptions.option1 = [...new Set(newAvailableOptions.option1)];
    newAvailableOptions.option2 = [...new Set(newAvailableOptions.option2)];
    newAvailableOptions.option3 = [...new Set(newAvailableOptions.option3)];

    setAvailableOptions(newAvailableOptions);
  };

  const handleOptionChange = (optionKey, value) => {
    setSelectedOptions(prev => {
      const newOptions = { ...prev, [optionKey]: value };
      const keys = ['option1', 'option2', 'option3'];
      const index = keys.indexOf(optionKey);
      for (let i = index + 1; i < keys.length; i++) {
        newOptions[keys[i]] = null;
      }
      return newOptions;
    });
    checkAllOptionsSelected();
  };

  const updateSelectedVariant = () => {
    const variant = getSelectedVariant();
    setSelectedVariant(variant);
    if (variant?.image) {
      const newActiveImage = productData.productDetails.product_images.find(img => img.src === variant.image);
      if (newActiveImage) {
        setActiveImage(newActiveImage);
      }
    } else {
      const mainImage = productData.productDetails.product_images.find(img => img.is_main === "1");
      if (mainImage) {
        setActiveImage(mainImage);
      }
    }
  };


  const getSelectedVariant = () => {
    return productData.productDetails.product_variants.find(v =>
    (
      (!selectedOptions.option1 || v.option1 === selectedOptions.option1) &&
      (!selectedOptions.option2 || v.option2 === selectedOptions.option2) &&
      (!selectedOptions.option3 || v.option3 === selectedOptions.option3)
    )
    ) || productData.productDetails.product_variants[0];
  };

  const isOptionAvailable = (optionKey, optionValue) => {
    return availableOptions[optionKey].includes(optionValue);
  };

  const isProductUnavailable = () => {
    const variant = getSelectedVariant();
    return (variant.allowed_inventory_quantity === 0 && variant.inventory_management) ||
      (variant.status !== '1' && variant.inventory_management);
  };

  const getDiscountPercentage = () => {
    const variant = getSelectedVariant();
    const price = variant?.price || productData.productDetails.price_msrp;
    const brand_discount = productData.productDetails.user.brand_discount;
    let discount = brand_discount?.discount_percentage ? parseFloat(brand_discount?.discount_percentage) : 0;
    let start_date = brand_discount?.start_date;
    let end_date = brand_discount?.end_date;
    let discount_percentage = 0;

    if (discount &&
      isCurrentTimeWithinDateRange(start_date, end_date) &&
      storeDetails?.user?.subscription &&
      storeDetails?.user?.subscription?.recurly_plan_code !== 'retailer-starter-monthly-plan') {
      discount_percentage = discount;
    }

    if (discount_percentage) {
      const prices = price.toString().split('-');
      const originalPrices = prices.map(v => parseFloat(v).toFixed(2));
      const discountedPrices = prices.map(v => {
        let discountPrice = v - ((v * discount_percentage) / 100);
        return parseFloat(discountPrice).toFixed(2);
      });

      return {
        originalPrice: originalPrices.join("-"),
        discountedPrice: discountedPrices.join("-"),
        hasDiscount: true
      };
    } else {
      const prices = price.toString().split('-');
      const formattedPrices = prices.map(v => parseFloat(v).toFixed(2));
      return {
        originalPrice: formattedPrices.join("-"),
        discountedPrice: null,
        hasDiscount: false
      };
    }
  };

  return {
    selectedOptions,
    allOptions,
    availableOptions,
    handleOptionChange,
    selectedVariant,
    activeImage,
    isProductUnavailable,
    isOptionAvailable,
    getDiscountPercentage,
    allOptionsSelected
  };
};

export default useProductOptions;