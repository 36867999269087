import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PatternFormat } from 'react-number-format';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import axios from '../../utilities/axios';
import * as API_END_POINT from '../../utilities/constant';
import visaLogo from '../../assets/images/visa-logo.svg';
import masterCardLogo from '../../assets/images/mastercard.svg';
import unionpayLogo from '../../assets/images/unionpay.svg';
import maestroLogo from '../../assets/images/maestro.svg';
import discoverLogo from '../../assets/images/discover.svg';
import amexLogo from '../../assets/images/amex.svg';

import OrderListing from '../OrderListing';
import { useStore } from '../../context/StoreContext';
import cardValidator from 'card-validator';
let cvvLength = 3;

const schema = yup.object().shape({
    holderName: yup.string().required('Please fill this field').matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.'),
    cardNumber: yup
        .string()
        .required('Please fill this field')
        .test('cardNumber', 'Incorrect card number', (value) => {
            if (value && typeof value === 'string') {
                const cardInfo = cardValidator.number(value);
                cvvLength = (cardInfo.card && cardInfo.card.type === "american-express" ? 4 : 3);
                return cardInfo.isValid;
            }
            return false;
        }),
    expiryMonth: yup.object().nullable().required('Please fill this field').test(
        'checkExpiryMonth',
        'Previous date followed by the current date is not allowed.',
        function (data) {
            if (this.parent?.expiryYear?.value) {
                const date = new Date();
                const currentYear = date?.getFullYear();
                const currentMonth = date?.getMonth() + 1;
                if (currentYear === this.parent?.expiryYear?.value && data?.value < currentMonth) {
                    return false;
                }
            }
            return true;
            //   return parseInt(this.parent.expiryYear) > parseInt(value);
        }
    ),
    expiryYear: yup.object().nullable().required('Please fill this field').test(
        'checkExpiryYear',
        'Previous date followed by the current date is not allowed.',
        function (data) {
            if (this.parent?.expiryMonth?.value) {
                const date = new Date();
                const currentYear = date?.getFullYear();
                const currentMonth = date?.getMonth() + 1;
                if (this.parent?.expiryMonth?.value < currentMonth && data?.value == currentYear) {
                    return false;
                }
            }
            return true;
            //   return parseInt(this.parent.expiryYear) > parseInt(value);
        }
    ),
    cvv: yup
        .string()
        .required('Please fill this field')
        .required('Please fill this field').test("cvv", "Invalid CVV", (value) => {
            const cvvValidation = cardValidator.cvv(value, cvvLength);
            return cvvValidation.isValid;
        }),
    addressLine1: yup.string().required('Please fill this field').min(4).max(40),
    state: yup.object().nullable().required('Please fill this field'),
    city: yup.string().required('Please fill this field').matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'please enter letters only and make sure there is not a space at beginning and end.').min(2).max(50),
    zip: yup
        .string()
        .nullable()
        .required('Please fill this field')
        .matches(/^\d{5}$/, 'Zip code should contain 5 digits'),

});

const PaymentDetails = () => {
    const navigate = useNavigate();
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [states, setStates] = useState([]);
    const [isOrderPlacing, setIsOrderPlacing] = useState(false);
    const { customerInfo, setCustomerInfo, brandsCartCalc, setStoreOrderStatus } = useStore();
    const [state, setState] = useState([]);

    const { register, handleSubmit, control, setValue, formState: { errors }, trigger } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const fetchStates = async (countryId) => {
        try {
            const response = await axios.get(API_END_POINT.STORE_STATES(countryId));
            if (response && response.data && response.data.code === 200) {
                setStates(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching states:', err);
        }
    };

    const getOrderTotalAmount = () => {
        return brandsCartCalc.reduce((total, brand) => total + brand.subtotal, 0).toFixed(2);
    };

    useEffect(() => {
        if (states?.length > 0) {
            const s = [];
            states?.map(v => {
                s.push({ value: v?.code, label: v?.name });
            })
            setState(s);
        }
    }, [states])

    useEffect(() => {
        fetchStates(1);
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 11 }, (_, i) => currentYear + i);
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        setYearList(years.map(year => ({ value: year, label: year.toString() })));
        setMonthList(months.map(month => ({ value: month, label: month.toString().padStart(2, '0') })));
    }, []);

    const onSubmit = async (data, event) => {
        setIsOrderPlacing(true);
        // eslint-disable-next-line no-undef
        const ps = new PassportCheckout();
        try {
            const token = await ps.getToken(event.target, {
                secureform: false,
                bindToken: false,
            });

            if (token?.token) {
                const body = {
                    first_name: customerInfo.firstName,
                    last_name: customerInfo.lastName,
                    email: customerInfo.contactEmail,
                    phone_number: customerInfo.phoneNumber,
                    shipping_address: {
                        address_1: customerInfo.addressLine1,
                        address_2: customerInfo.addressLine2 ? customerInfo.addressLine2 : null,
                        country: customerInfo.country.label,
                        city: customerInfo.city,
                        state: customerInfo.state.label,
                        zipcode: customerInfo.zip,
                    },
                    is_billing_address_same: data.billingAddress,
                    billing_address: {
                        address_1: data.addressLine1,
                        address_2: data.addressLine2,
                        country: customerInfo.country.label,
                        city: data.city,
                        state: data.state.label,
                        zipcode: data.zip,
                    },
                    token: token.token,
                    total_amount: getOrderTotalAmount(),
                };
                body.device_id = localStorage.getItem('device_id');

                const response = await axios.post(API_END_POINT.STORE_PLACE_ORDER, body);
                if (response.data.success) {
                    setStoreOrderStatus(response.data.data);
                    navigate('/order-confirmation');
                } else {
                    console.error('Error placing order:', response.data.message);
                }
            } else {
                console.error('Failed to get token from PassportCheckout');
            }
        } catch (error) {
            console.error('Error in payment process:', error);
        } finally {
            setIsOrderPlacing(false);
        }
    };


    const checkAddress = async (event) => {
        if (event.target.checked) {
            setValue('addressLine1', customerInfo.addressLine1);
            setValue('addressLine2', customerInfo.addressLine2);
            setValue('country', customerInfo.country);
            setValue('state', customerInfo.state);
            setValue('city', customerInfo.city);
            setValue('zip', customerInfo.zip);
        } else {
            setValue('addressLine1', '');
            setValue('addressLine2', '');
            setValue('country', { value: 1, label: 'United States' });
            setValue('state', '');
            setValue('city', '');
            setValue('zip', '');
        }

        await trigger(['addressLine1', 'addressLine2', 'country', 'state', 'city', 'zip']);
    };

    const getSelectBoxStyle = (isError = false) => {
        return {
            control: (styles) => {
                return {
                    ...styles,
                    borderColor: isError ? '#eb5757 !important' : '#cccccc',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                        boxShadow: 'none !important',
                    },
                };
            },
            container: (style) => {
                return {
                    ...style,
                    marginTop: '5px',
                    marginRight: '1px',
                };
            },
        };
    };

    const getSelectBoxTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#fbf5f0',
            primary: '#bd6f34',
        },
    });


    return (
        <div className="relative payment-details-container">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <h1 className="text-2xl font-bold mb-6 text-gray-800">Payment details</h1>
                <div className="payment-details-layout">
                    <div className="order-summary space-y-4 border border-gray-300 rounded-lg p-4">
                        <OrderListing isFromPayment='true' />
                    </div>
                    <div className="payment-form">
                        <div className="bg-white border border-gray-300 rounded-lg p-4 mb-6">
                            <div className="flex justify-between items-center mb-4 border-b border-gray-300 pb-3">
                                <h2 className="text-lg font-semibold text-gray-800">Customer information</h2>
                                <button
                                    className="text-white bg-gray-700 hover:bg-gray-800 px-3 py-1 rounded text-sm flex items-center"
                                    onClick={() => navigate('/checkout')}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                    Edit
                                </button>
                            </div>
                            
                            <div className="text-sm space-y-1 text-gray-600">
                                <p>{customerInfo.contactEmail}</p>
                                <p>{customerInfo.firstName} {customerInfo.lastName}</p>
                                <p>{customerInfo.addressLine1}</p>
                                <p>{customerInfo.addressLine2}</p>
                                <p>{customerInfo.city}, {customerInfo.state.label}, {customerInfo.country.label}, {customerInfo.zip}</p>
                                <p>{customerInfo.phoneNumber}</p>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="bg-white space-y-6 border border-gray-300 rounded-lg p-4">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-semibold text-gray-800">Payment</h2>
                                <div className="flex space-x-2">
                                    <img src={visaLogo} alt="Visa" className="h-4 sm:h-6" />
                                    <img src={masterCardLogo} alt="Mastercard" className="h-4 sm:h-6" />
                                    <img src={unionpayLogo} alt="UnionPay" className="h-4 sm:h-6" />
                                    <img src={amexLogo} alt="AMEX" className="h-10 sm:h-6" />
                                </div>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Name on card <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register('holderName')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                        placeholder="Enter card holder name"
                                    />
                                    {errors.holderName && <p className="mt-1 text-sm text-red-600">{errors.holderName.message}</p>}
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Card number <span className="text-red-500">*</span>
                                    </label>
                                    <Controller
                                        control={control}
                                        name="cardNumber"
                                        render={({ field: { onChange, name, value, onBlur } }) => (
                                            <PatternFormat
                                                format="################"
                                                value={value}
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                                placeholder="Enter card number"
                                            />
                                        )}
                                    />
                                    {errors.cardNumber && <p className="mt-1 text-sm text-red-600">{errors.cardNumber.message}</p>}
                                </div>

                                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Expiry Month <span className="text-red-500">*</span>
                                        </label>
                                        <Controller
                                            name="expiryMonth"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    options={monthList}
                                                    placeholder="MM"
                                                    styles={getSelectBoxStyle(errors.expiryMonth)}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    theme={getSelectBoxTheme}
                                                />
                                            )}
                                        />
                                        {errors.expiryMonth && <p className="mt-1 text-sm text-red-600">{errors.expiryMonth.message}</p>}
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Expiry Year <span className="text-red-500">*</span>
                                        </label>
                                        <Controller
                                            name="expiryYear"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    options={yearList}
                                                    placeholder="YYYY"
                                                    styles={getSelectBoxStyle(errors.expiryYear)}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    theme={getSelectBoxTheme}
                                                />
                                            )}
                                        />
                                        {errors.expiryYear && <p className="mt-1 text-sm text-red-600">{errors.expiryYear.message}</p>}
                                    </div>
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            CVV <span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register('cvv')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                            placeholder="123"
                                            maxLength="4"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                        />
                                        {errors.cvv && <p className="mt-1 text-sm text-red-600">{errors.cvv.message}</p>}
                                    </div>
                                </div>


                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="billingAddress"
                                        {...register('billingAddress')}
                                        onChange={checkAddress}
                                        className="h-4 w-4 text-blue-600 focus:ring-black border-gray-300 rounded"
                                    />
                                    <label htmlFor="billingAddress" className="ml-2 block text-sm text-gray-900">
                                        Use shipping address as billing address
                                    </label>
                                </div>

                                <h2 className="text-lg font-semibold mt-6 mb-4 text-gray-800">Billing address</h2>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Address<span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register('addressLine1')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                        placeholder="Suite/Building number, floor number, street number etc..."
                                    />
                                    {errors.addressLine1 && <p className="mt-1 text-sm text-red-600">{errors.addressLine1.message}</p>}
                                </div>
                                {/* 
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Address line 2
                                </label>
                                <input
                                    type="text"
                                    {...register('addressLine2')}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                    placeholder="Other address details, if needed"
                                />
                            </div> */}

                                <div className="flex space-x-4">
                                    {/* <div className="w-1/2">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Country <span className="text-red-500">*</span>
                                    </label>
                                    <Select
                                        options={[{ value: 'US', label: 'United States' }]}
                                        defaultValue={{ value: 'US', label: 'United States' }}
                                        isDisabled
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                    />
                                </div> */}
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            State <span className="text-red-500">*</span>
                                        </label>
                                        <Controller
                                            name="state"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={state}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select state"
                                                    styles={getSelectBoxStyle()}
                                                    components={{
                                                        IndicatorSeparator:
                                                            () => null,
                                                    }}
                                                    theme={(theme) =>
                                                        getSelectBoxTheme(
                                                            theme
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                        {errors.state && <p className="mt-1 text-sm text-red-600">{errors.state.message}</p>}
                                    </div>
                                </div>

                                <div className="flex space-x-4">
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            City <span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register('city')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                            placeholder="Enter your city name"
                                        />
                                        {errors.city && <p className="mt-1 text-sm text-red-600">{errors.city.message}</p>}
                                    </div>
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            ZIP <span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register('zip')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                                            placeholder="12345"
                                            maxLength="5"
                                        />
                                        {errors.zip && <p className="mt-1 text-sm text-red-600">{errors.zip.message}</p>}
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="w-full mt-6 bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-4 rounded-full transition duration-300"
                                disabled={isOrderPlacing}
                            >
                                {isOrderPlacing ? 'Processing...' : 'Pay Now'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {isOrderPlacing && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <div className="loader mb-4 mx-auto"></div>
                        <p className="text-lg font-semibold">Processing your order...</p>
                        <p className="text-sm text-gray-600 mt-2">Please do not refresh the page or close the window.</p>
                    </div>
                </div>
            )}

            <style jsx>{`
                .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #f97316;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 1s linear infinite;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                      @media (max-width: 1023px) {
                    .payment-details-layout {
                        display: flex;
                        flex-direction: column;
                    }
                    .order-summary {
                        order: -1;
                        margin-bottom: 2rem;
                    }
                }
                @media (min-width: 1024px) {
                    .payment-details-layout {
                        display: flex;
                        flex-direction: row;
                        gap: 2rem;
                    }
                    .payment-form {
                        width: 60%;
                        order: -1;
                    }
                    .order-summary {
                        width: 40%;
                    }
                }
            `}</style>

        </div>


    );
};

export default PaymentDetails;