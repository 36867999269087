import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const StoreNotExist = () => {

  const handleBackToShopDot = () => {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace('/store-not-available', '');
    window.history.pushState({}, '', newUrl);
    window.location.reload();
  };

  return (
    <div className="flex flex-col items-center justify-center h-80 p-4 m-4 rounded-lg" style={{backgroundColor: '#FCF6F2'}}>
      <div className="max-w-3xl w-full">
        <div className="mb-6">
          <a onClick={handleBackToShopDot} className="flex items-center justify-center text-orange-500 hover:text-orange-600 transition-colors text-sm">
            <ArrowLeft className="w-4 h-4 mr-1" />
            <span>Back to ShopDot</span>
          </a>
        </div>
        <h1 className="text-5xl font-semibold text-gray-800 text-center">
          This store does not exist
        </h1>
      </div>
    </div>
  );
};

export default StoreNotExist;