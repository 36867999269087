import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import useProductData from '../../hooks/useProductData';
import ProductImages from '../../components/ProductImages';
import ProductInfo from '../../components/ProductInfo';
import ProductTabs from '../../components/ProductTabs';
import SkeletonLoader from '../../components/SkeletonLoader';
import { useStore } from '../../context/StoreContext';

const ProductDetails = () => {
  const { storeDetails, addToCart } = useStore();
  const { productId } = useParams();
  const navigate = useNavigate();
  const { productData, loading, error } = useProductData(productId);
  const [activeImage, setActiveImage] = useState(null);

  useEffect(() => {
    if (productData && productData.productDetails.product_images.length > 0) {
      setActiveImage(productData.productDetails.product_images[0]);
    }
  }, [productData]);

  if (error) {
    return <div className="container mx-auto px-8 py-8">Error: {error.message}</div>;
  }

  const handleAddToCart = (selectedVariant, customizableNote) => {
    const body = {
      "brands": [
        {
          "brand_user_id": productData?.productDetails?.user?.id,
          "products": [
            {
              "product_id": productData?.productDetails?.id,
              "variants": [
                {
                  "variant_id": selectedVariant?.id,
                  "quantity": 1
                }
              ],
              customizableNote: productData?.productDetails?.is_customizable ? customizableNote : null,
            }
          ]
        }
      ],
    };
    addToCart(body);
  };

  const handleImageChange = (newActiveImage) => {
    setActiveImage(newActiveImage);
  };

  return (
    <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Link to="/">
        <div className=" hidden md:flex items-center mb-6 cursor-pointer" >
          <ChevronLeft className="mr-2" />
          <span className="text-lg">Back</span>
        </div>
      </Link>
      <div className="flex flex-col lg:flex-row gap-8">
        {loading ? (
          <SkeletonLoader type="image" />
        ) : (
          <ProductImages
            images={productData?.productDetails?.product_images}
            activeImage={activeImage}
          />
        )}
        {loading ? (
          <SkeletonLoader type="productInfo" />
        ) : (
          <ProductInfo
            productData={productData}
            storeDetails={storeDetails}
            onAddToCart={handleAddToCart}
            onImageChange={handleImageChange}
          />
        )}
      </div>
      {loading ? (
        <SkeletonLoader type="tabs" />
      ) : (
        <ProductTabs productData={productData} />
      )}
    </main>
  );
};

export default ProductDetails;