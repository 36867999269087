import request from 'axios';

const axios = request;

axios.interceptors.request.use(
  async (config) => {
    config.headers['store-domain'] = window.location.hostname;
    // config.headers['store-domain'] = 'aamir8.myshopdotdev.com';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.error('Axios response error:', error);
    return Promise.reject(error);
  }
);


export default axios;