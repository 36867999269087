const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const BASE_URL = `${ENDPOINT}/api/v1`;

export const STORE_DETAILS = `${BASE_URL}/store`;
export const STORE_BRANDS = `${BASE_URL}/store/brands`;
export const STORE_PRODUCTS = `${BASE_URL}/store/products`;
export const STORE_PRODUCT_DETAILS = (id) => `${BASE_URL}/store/product/${id}`;
export const STORE_ADD_TO_CART = `${BASE_URL}/store/cart/add`;
export const STORE_CART_DATA = (deviceId, zipcode) => `${BASE_URL}/store/cart/list?device_id=${deviceId}${(zipcode && zipcode !== undefined) ? "&zipcode="+zipcode : ''}`;
export const STORE_PLACE_ORDER = `${BASE_URL}/store/orders`;
export const STORE_CART_REMOVE_PRODUCT = `${BASE_URL}/store/cart/item/remove`;
export const STORE_ORDER_DETAILS = (id) => `${BASE_URL}/store/orders/${id}`;
export const STORE_COUNTRY = `${BASE_URL}/store/general/countries`;
export const STORE_STATES = (id) => `${BASE_URL}/store/general/countries/${id}/states`;
